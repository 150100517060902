import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1079.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1079.000000) scale(0.100000,-0.100000)">
					<path d="M5150 8193 c-571 -60 -1064 -257 -1499 -600 -128 -100 -378 -354
					-472 -478 -310 -408 -490 -835 -565 -1340 -23 -151 -26 -592 -6 -730 70 -477
					210 -848 463 -1225 109 -162 195 -266 339 -411 353 -355 803 -613 1286 -739
					888 -230 1838 -11 2533 586 206 177 364 355 517 585 201 302 342 640 413 994
					70 346 69 765 0 1115 -221 1110 -1119 1995 -2234 2204 -175 33 -330 46 -535
					44 -113 0 -221 -3 -240 -5z m521 -248 c422 -44 803 -182 1159 -418 142 -94
					233 -169 373 -306 247 -241 437 -516 561 -813 24 -57 44 -106 44 -111 1 -4 9
					-24 17 -45 25 -58 87 -283 81 -289 -3 -4 -1 -11 4 -18 5 -6 20 -70 32 -141 20
					-113 23 -163 22 -414 0 -251 -2 -300 -22 -410 -86 -476 -256 -854 -549 -1217
					-85 -105 -360 -376 -374 -367 -5 3 -9 1 -9 -5 0 -27 -280 -211 -461 -302 -487
					-244 -1072 -327 -1604 -228 -112 21 -218 45 -245 56 -8 3 -23 8 -32 9 -47 10
					-214 72 -319 119 -718 325 -1248 957 -1439 1715 -119 472 -103 961 47 1425
282 873 1024 1537 1923 1721 255 53 536 66 791 39z"/>
					<path d="M5210 7934 c-181 -14 -433 -65 -615 -126 -1147 -385 -1868 -1523
-1724 -2722 72 -603 373 -1186 819 -1583 386 -345 829 -555 1340 -635 181 -29
547 -31 720 -4 405 62 734 186 1060 401 260 170 514 419 685 670 37 55 73 105
79 110 6 6 12 15 13 20 3 21 50 95 56 90 4 -3 5 -2 2 1 -2 4 13 41 35 84 117
232 200 491 246 767 19 111 29 612 14 679 -5 21 -16 82 -24 134 -18 111 -21
125 -35 175 -6 21 -8 42 -5 46 2 4 0 10 -5 13 -5 3 -12 23 -17 43 -4 21 -8 40
-9 43 -2 10 -58 157 -87 230 -135 335 -377 672 -665 929 -74 65 -230 185 -289
222 -19 11 -34 25 -34 30 0 6 -4 8 -8 5 -5 -3 -24 5 -43 18 -50 34 -261 139
-359 179 -293 119 -570 175 -900 181 -107 2 -220 2 -250 0z m400 -641 c130
-40 196 -79 281 -165 148 -149 175 -409 62 -591 -79 -128 -241 -232 -414 -266
l-44 -9 50 -1 c255 -6 470 -134 572 -342 45 -90 66 -157 61 -192 -4 -31 42
-50 123 -51 55 -1 73 3 98 22 l32 23 -3 -203 c-1 -112 -5 -237 -8 -278 l-5
-74 -24 74 c-43 135 -121 274 -188 332 -19 17 -19 16 -32 -45 -35 -168 -164
-329 -327 -407 -70 -33 -166 -60 -215 -60 l-34 0 25 -20 c30 -24 148 -74 330
-141 164 -60 297 -118 316 -136 8 -8 14 -12 14 -9 0 8 111 -64 149 -96 18 -15
30 -32 26 -38 -3 -6 0 -7 9 -4 10 4 19 -1 23 -12 4 -11 16 -26 28 -36 28 -22
78 -115 69 -130 -4 -6 -3 -8 3 -5 13 8 34 -62 41 -136 4 -44 1 -61 -9 -68 -10
-6 -10 -9 -1 -9 17 0 16 -109 -3 -188 -14 -60 -52 -161 -64 -172 -4 -3 -15
-23 -26 -45 -27 -56 -179 -205 -245 -241 -139 -76 -252 -105 -412 -105 -107 0
-132 4 -222 32 -56 17 -158 55 -228 84 -70 29 -152 59 -183 65 -50 10 -60 9
-98 -9 -28 -14 -53 -37 -71 -66 -16 -25 -30 -45 -30 -45 -2 0 9 136 34 420 5
69 10 145 10 169 0 58 12 45 40 -44 46 -143 147 -301 243 -376 128 -101 265
-144 462 -144 110 1 142 4 205 25 41 13 81 24 87 23 7 0 10 3 7 8 -3 5 -1 9 5
9 5 0 34 23 65 51 39 35 67 74 93 126 35 70 37 80 37 171 0 83 -4 105 -27 155
-57 126 -231 247 -489 341 -281 103 -443 198 -572 337 -61 67 -146 208 -146
246 0 12 -49 14 -312 13 -172 -1 -360 -1 -418 0 -104 1 -105 1 -74 18 76 42
115 91 143 175 21 63 21 75 22 933 0 955 2 915 -61 1014 -35 55 -73 86 -129
106 -30 12 69 13 624 11 630 -3 663 -4 725 -24z m-1022 -2376 c2 -10 -3 -17
-12 -17 -18 0 -29 16 -21 31 9 14 29 6 33 -14z m-1256 -26 c28 -25 33 -36 33
-75 0 -36 -6 -51 -26 -72 -23 -22 -38 -27 -98 -32 l-71 -5 0 107 0 106 65 0
c57 0 68 -3 97 -29z m719 18 c29 -15 14 -30 -20 -18 -18 7 -37 6 -55 -1 -42
-16 -30 -42 28 -61 27 -8 56 -25 64 -37 32 -49 -41 -96 -113 -72 -20 7 -34 17
-32 23 3 7 11 10 18 7 8 -2 34 -4 59 -5 38 0 45 3 45 20 0 14 -13 24 -50 38
-75 27 -93 59 -55 97 22 22 78 27 111 9z m-451 -24 c0 -9 9 -15 25 -15 14 0
25 -4 25 -10 0 -5 -11 -10 -26 -10 -24 0 -25 -2 -22 -52 2 -44 6 -53 23 -56
34 -7 39 -21 10 -28 -40 -10 -65 23 -65 86 0 29 -5 51 -12 54 -10 5 -10 7 0
12 6 3 12 12 12 20 0 8 7 14 15 14 8 0 15 -7 15 -15z m-95 -29 c26 -19 36
-146 11 -146 -9 0 -16 5 -16 10 0 7 -7 7 -21 0 -31 -17 -78 -3 -85 26 -9 36
12 54 62 54 32 0 44 4 44 15 0 24 -37 38 -71 26 -23 -8 -29 -7 -29 4 0 26 73
34 105 11z m280 0 c26 -19 36 -146 11 -146 -9 0 -16 5 -16 10 0 7 -7 7 -21 0
-31 -17 -78 -3 -85 26 -9 36 12 54 62 54 32 0 44 4 44 15 0 24 -37 38 -71 26
-23 -8 -29 -7 -29 4 0 26 73 34 105 11z m442 -8 c13 -12 23 -32 23 -45 0 -22
-4 -23 -67 -23 l-66 0 19 -21 c16 -17 26 -20 62 -15 51 7 55 -13 5 -27 -39
-12 -83 8 -101 46 -36 74 64 142 125 85z m83 10 c0 -9 3 -9 12 0 14 14 48 16
48 3 0 -6 -12 -16 -27 -23 -25 -12 -28 -19 -31 -71 -2 -42 -7 -57 -17 -57 -12
0 -15 17 -15 80 0 64 3 80 15 80 8 0 15 -5 15 -12z m100 3 c0 -5 11 -33 24
-62 l24 -54 27 63 c15 34 34 62 41 62 18 0 -48 -154 -68 -158 -10 -2 -25 21
-46 72 -37 87 -37 86 -17 86 8 0 15 -4 15 -9z m180 -71 c0 -64 -3 -80 -15 -80
-12 0 -15 16 -15 80 0 64 3 80 15 80 12 0 15 -16 15 -80z m156 66 c26 -20 10
-32 -22 -17 -22 10 -31 9 -50 -4 -26 -17 -33 -62 -12 -83 14 -14 64 -16 73 -2
3 5 12 7 21 3 21 -8 0 -30 -36 -38 -32 -7 -84 15 -94 40 -29 76 56 147 120
101z m162 -3 c12 -10 24 -30 28 -45 l6 -28 -66 0 c-36 0 -66 -4 -66 -9 0 -20
32 -33 70 -28 43 6 52 -6 19 -24 -29 -15 -85 -3 -104 24 -51 73 45 167 113
110z m146 11 c28 -11 17 -27 -14 -21 -71 15 -82 -23 -12 -44 39 -12 47 -18 47
-39 0 -34 -40 -55 -88 -46 -54 10 -48 32 10 32 63 -1 67 19 6 36 -36 10 -49
19 -51 35 -6 40 51 67 102 47z m-314 -611 c15 -31 16 -31 110 -38 52 -3 112
-8 133 -11 l37 -6 0 -51 c0 -29 -7 -137 -14 -242 -8 -104 -18 -237 -22 -294
l-6 -104 -142 7 c-140 7 -141 7 -151 -16 -14 -32 -69 -37 -85 -8 -23 42 19 92
59 70 11 -5 22 -10 26 -10 3 0 21 33 40 73 l35 72 -43 38 c-142 129 -146 131
-165 113 -58 -52 -137 29 -82 84 27 27 57 25 83 -4 l21 -24 103 70 c57 38 103
73 103 77 0 4 -11 38 -23 77 -22 65 -26 69 -51 66 -35 -5 -56 13 -56 47 0 59
64 69 90 14z"/>
					<path d="M4840 6765 l0 -485 203 0 c263 0 332 18 426 105 87 81 135 190 147
332 13 160 -37 310 -137 412 -99 101 -172 121 -443 121 l-196 0 0 -485z"/>
					<path d="M4922 6212 l-82 -3 0 -421 c1 -231 4 -441 7 -465 9 -66 58 -132 124
-165 30 -16 64 -29 77 -30 21 -3 23 1 27 85 9 151 64 274 167 373 64 62 145
117 158 109 6 -3 10 -2 10 3 0 25 162 73 288 87 72 8 75 14 44 107 -41 125
-127 230 -231 281 l-65 32 -221 5 c-121 3 -257 4 -303 2z"/>
					<path d="M5658 5691 c-73 -24 -116 -51 -168 -107 -60 -65 -82 -121 -83 -213
-2 -81 24 -178 48 -187 20 -8 112 55 173 117 33 34 68 84 92 134 42 85 45 96
56 206 l7 69 -34 -1 c-19 0 -59 -8 -91 -18z"/>
					<path d="M3228 4892 c-28 -3 -28 -4 -28 -78 l0 -74 45 0 c80 0 120 65 75 122
-21 27 -47 35 -92 30z"/>
					<path d="M3420 4761 c0 -32 52 -37 72 -7 14 21 8 26 -32 26 -33 0 -40 -4 -40
-19z"/>
					<path d="M3700 4761 c0 -32 52 -37 72 -7 14 21 8 26 -32 26 -33 0 -40 -4 -40
-19z"/>
					<path d="M4142 4834 c-34 -24 -26 -34 28 -34 54 0 69 19 31 40 -26 13 -33 13
-59 -6z"/>
					<path d="M4828 4839 c-36 -21 -21 -39 32 -39 54 0 62 10 28 34 -26 18 -36 19
-60 5z"/>
					<path d="M4667 4256 c-11 -28 4 -47 32 -44 20 2 26 8 26 28 0 20 -6 26 -26 28
-17 2 -29 -3 -32 -12z"/>
					<path d="M4750 4200 c0 -8 42 -134 46 -139 2 -2 47 25 101 60 66 44 91 65 77
66 -66 9 -224 18 -224 13z"/>
					<path d="M4893 4099 l-91 -61 25 -74 c14 -41 26 -74 28 -72 5 6 135 268 133
268 -2 0 -44 -27 -95 -61z"/>
					<path d="M4927 3995 l-67 -134 46 -133 c26 -73 49 -139 53 -146 4 -7 15 112
25 263 10 152 16 278 14 280 -2 2 -34 -56 -71 -130z"/>
					<path d="M4678 3953 c-54 -36 -95 -69 -93 -73 3 -4 45 -44 94 -89 l89 -81 37
77 37 78 -24 74 c-13 41 -28 76 -33 77 -6 1 -54 -27 -107 -63z"/>
					<path d="M4480 3916 c-6 -8 -10 -25 -8 -38 2 -19 9 -23 38 -23 29 0 36 4 38
23 4 28 -14 52 -38 52 -10 0 -23 -7 -30 -14z"/>
					<path d="M4812 3765 l-32 -65 48 -44 c64 -60 116 -105 119 -103 4 4 -92 277
-97 277 -3 0 -20 -29 -38 -65z"/>
					<path d="M4737 3612 c-30 -58 -33 -71 -21 -76 9 -3 62 -6 119 -6 l104 0 -52
48 c-29 26 -54 49 -57 52 -3 3 -17 16 -32 28 l-26 24 -35 -70z"/>
					<path d="M4620 3535 c-15 -18 -7 -43 16 -52 24 -9 46 24 33 48 -12 23 -32 24
-49 4z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
